<template>
  <div>
    <div class="studyLife">
      <studyHead></studyHead>
      <div class="classification">
        <div class="things" v-for="item of 8">新加坡美食</div>
      </div>
    </div>
    <showhotspot></showhotspot>
  </div>
</template>

<script>
import studyHead from "@/components/studyHead";
import showhotspot from "@/components/showhotspot";
export default {
  components: {
    studyHead,
    showhotspot
  },
}
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.studyLife{
  width: 80%;
  margin: 0 auto;
  .classification{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .things{
      height: 27vh;
      width: 18vw;
      margin-top: 3vh;
      line-height: 27vh;
      text-align: center;
      color: white;
      font-size: 2vh;
      background-image: url("../../assets/myImage/food.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>

